import React from 'react';
import Sidebar from '../components/Sidebar';
import UpperBar from "../components/UpperBar";
import WhiteBlockSettings from "../components/WhiteBlockSettings";
import ProfileComponent from "../components/ProfileComponent";

const ReferralComp = () => {
    return (
        <div id="wrapper">
            <Sidebar />
            <div id="page-wrapper" className="gray-bg dashbard-1">
                <UpperBar />
                <WhiteBlockSettings />
                <ProfileComponent />
            </div>
        </div>
    );
};

export default ReferralComp;