import React, { createContext, useState, useContext } from 'react';
import WithdrawalModal from "./WithdrawalModal";

const WithdrawalContext = createContext();

export const WithdrawalProvider = ({ children }) => {
    const [clients, setClients] = useState(null);

    const showClients = (clients) => {
        setClients(clients);
    };

    const clearClients = () => {
        setClients(null);
    };

    return (
        <WithdrawalContext.Provider value={{ clients, showClients, clearClients }}>
            {children}
            {clients && <WithdrawalModal clients={clients} onClose={clearClients} />}
        </WithdrawalContext.Provider>
    );
};

export const useError = () => useContext(WithdrawalContext);