import React, {useEffect, useState} from 'react';
import Sidebar from '../components/Sidebar';
import UpperBar from "../components/UpperBar";
import WhiteBlock from "../components/WhiteBlock";
import WithdrawalWindow from "../components/WithdrawalWindow";
import Notification from "../components/Notification";
import WithdrawalModal from "../components/WithdrawalModal";
import {addWithdrawal, getWithdrawals} from "../api/withdrawal";
import {getBalance} from "../api/balance";
import {useError} from "../components/ErrorContext";
import ErrorModal from "../components/ErrorWindow";

const WithdrawalComp = () => {
    const {showError} = useError();

    const [notification, setNotification] = useState(null);
    const [clients, setClients] = useState(null);
    const [clientEntities, setClientEntities] = useState([]);
    const [amount, setAmount] = useState('');
    const [balance, setBalance] = useState('');
    const [withdrawals, setWithdrawals] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {

        const fetchBalance = async () => {
            try {
                const response = await getBalance();
                setBalance(response['balance'])
            } catch (error) {
                console.error('Failed to fetch referral codes:', error.message);
            }
        }

        const fetchWithdrawals = async () => {
            try {
                const data = await getWithdrawals();
                // console.log('API Data:', data); // Log the data to check its structure
                if (Array.isArray(data)) {
                    setWithdrawals(data);
                } else {
                    throw new Error('Data is not an array');
                }
            } catch (error) {
                throw new Error(error);
            }
        };

        const fetchClientEntities = async () => {
            try {
                const response = await addWithdrawal();
                // console.log('API Data:', response); // Log the data to check its structure
                if (Array.isArray(response.clientIds)) {
                    setClientEntities(response.clientIds); // Set the array of clients
                } else {
                    console.error('Unexpected data format:', response);
                    setClientEntities([]);
                }
            } catch (error) {
                throw new Error(error);
            }
        }

        fetchClientEntities();
        fetchBalance();
        fetchWithdrawals();
    }, []);

    const fetchClientEntities = async () => {
            try {
                const response = await addWithdrawal();
                // console.log('API Data:', response); // Log the data to check its structure
                if (Array.isArray(response.clientIds)) {
                    setClientEntities(response.clientIds); // Set the array of clients
                } else {
                    console.error('Unexpected data format:', response);
                    setClientEntities([]);
                }
            } catch (error) {
                throw new Error(error);
            }
        }

    const fetchWithdrawals = async () => {
        try {
            const data = await getWithdrawals();
            // console.log('API Data:', data); // Log the data to check its structure
            if (Array.isArray(data)) {
                setWithdrawals(data);
            } else {
                throw new Error('Data is not an array');
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleWithdrawal = async (client_id) => {
        const numericAmount = parseFloat(amount);
        if (isNaN(numericAmount) || numericAmount <= 0) {
            showError('Введите верную сумму, большую 0.');
            return;
        }

        try {
            const response = await addWithdrawal(amount, client_id);
            // console.log(response);
            if ('balance' in response) {
                fetchWithdrawals();
                setBalance(response['balance']);
                setAmount(0);
                setNotification('Успешный вывод средств!');
            } else {
                showError('Ошибка при выводе средств!');
            }
        } catch (error) {
            console.error('Failed to fetch clicks:', error.message);
            showError('Ошибка при выводе средств!');
        } finally {
            clearClients();
        }
    };

    const handleClients = async () => {
        const numericAmount = parseFloat(amount);
        if (isNaN(numericAmount) || numericAmount <= 0) {
            showError('Введите верную сумму, большую 0.');
            return;
        }

        await fetchClientEntities();

        if (Array.isArray(clientEntities) && clientEntities.length === 0){
            showError('Ошибка при выводе средств! У вас нет ни одного клиента.');
        }
        else if (Array.isArray(clientEntities) && clientEntities.length === 1){
            handleWithdrawal(clientEntities[0][0]);
        }
        else {
            setClients(clientEntities);
        }
    };

    const clearClients = () => {
        setClients(null);
    };

    const handleError = (errorMessage) => {
        setError(errorMessage);
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <div id="wrapper">
            <Sidebar/>
            <div id="page-wrapper" className="gray-bg dashbard-1">
                <UpperBar/>
                <WhiteBlock/>
                <WithdrawalWindow notification={notification} setNotification={setNotification} amount={amount}
                                  handleClients={handleClients} balance={balance}
                                  setAmount={setAmount} withdrawals={withdrawals}/>
                {notification && (
                    <Notification
                        message={notification}
                        onClose={() => setNotification(null)}
                    />
                )}
            </div>

            {clients && <WithdrawalModal clients={clients} onClose={clearClients} onClientSelect={handleWithdrawal}
                                         handleError={handleError} clearError={clearError}
                                         setClientEntities={setClientEntities}/>}
            {error && <ErrorModal message={error} onClose={clearError}/>}
        </div>
    );
};

export default WithdrawalComp;