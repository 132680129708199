import React, {createContext, useState, useContext} from 'react';
import ErrorModal from "./ErrorWindow";

const ErrorContext = createContext();

export const ErrorProvider = ({children}) => {
    const [error, setError] = useState(null);

    const showError = (message) => {
        setError(message);
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <ErrorContext.Provider value={{error, showError, clearError}}>
            {children}
            {error && <ErrorModal message={error} onClose={clearError}/>}
        </ErrorContext.Provider>
    );
};

export const useError = () => useContext(ErrorContext);