import React from 'react';

const ConfirmModal = ({message, onConfirm, onCancel}) => {
    return (
        <div className="overlay">
            <div className="sc-saxv9r-2 fxxhPo modal-dialog modal-lg" tabIndex="-1"
                 data-html2canvas-ignore="true">
                <div className="modal-content">
                    <div className="modal-header"><h4 className="modal-title mb-3">Вы действительно хотите удалить реферельный код?</h4>
                    </div>
                    <div className="modal-body">

                    </div>
                    <div className="modal-footer border-top-0">
                        <button className="sc-1eg8ud2-0 jWwwsM btn btn-default btn-lg" onClick={onConfirm}
                                type="button">Да
                        </button>
                        <div className="button-wrapper">
                            <button className="sc-1eg8ud2-0 jWwwsM btn btn-default btn-lg" onClick={onCancel}
                                    type="button">Нет
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;