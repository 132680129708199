import React, {useEffect, useState} from "react";
import {getUserReferralCodes, createUserReferralCode, deleteReferralCode} from '../api/referrals';
import {ESU_DOMAIN} from "../const";
import Notification from "./Notification";
import CustomSelect from "./CustomSelect";

const ReferralCodesPage = ({
                               notification,
                               setNotification,
                               referralCodes,
                               handleDeleteReferralCode,
                               newReferralName,
                               setNewReferralName,
                               handleCreateReferralCode,
                               openEditReferralCodeModal,
                               setReferralCode,
                               setNewReferralCodeName,
                               newReferralNameField,
                               setNewReferralNameField,
                               handleDeleteClick
                           }) => {

    const isSmallScreen = window.innerWidth < 1100;
    const formControlHeight = isSmallScreen ? '2em' : 'auto';
    const options = ['Изменить', 'Удалить'];
    const [selectedOption, setSelectedOption] = useState('');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setNotification('Ссылка скопирована!');
    };

    const handleSelectOption = (selectedValue, referralCode) => {
        if (selectedValue === 'Изменить') {
            setNewReferralName(referralCode.name);
            setReferralCode(referralCode.code); // Set the code in state
            openEditReferralCodeModal(referralCode.code, referralCode.name);
        }
        if (selectedValue === 'Удалить') {
            setReferralCode(referralCode.code);
            handleDeleteClick();
        }
        setSelectedOption(selectedValue);
    };

    return (
        <div className="wrapper wrapper-content ecommerce animated fadeInRight">
            <div className="row">
                <div className="col-lg-12">
                    <ul className="nav nav-tabs" role="tablist">
                        <li><a className="nav-link" href="/statistics" data-panel-id=".0">Статистика</a></li>
                        <li><a className="nav-link active" to="/referral-page" data-panel-id=".1"
                               style={{marginBottom: '-2px', borderBottom: 'none', zIndex: '1010'}}>Ссылки</a></li>
                        <li><a className="nav-link" href="/withdrawal-page" data-panel-id=".2">Вывод средств</a>
                        </li>
                    </ul>
                    <div className="ibox-content text-start py-3">
                        <h3>Ваши реферальные ссылки:</h3>
                        <br/>

                        <div>
                            <div className="row">
                                <div className="col-4 col-sm-2"><span className="sc-d02gry-2 bffEpg" style={{
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>Название</span></div>
                                <div className="col-2"> {/* Spacer column */}
                                    <div style={{height: '1px'}}></div>
                                    {/* Adjust width for spacing */}
                                </div>
                                <div className="col-8 col-sm-3"><span
                                    className="sc-d02gry-2 bffEpg"
                                    style={{fontWeight: 'bold', fontSize: '15px'}}>Ссылка</span>
                                </div>
                                <div className="col-2"> {/* Spacer column */}
                                    <div style={{height: '1px'}}></div>
                                    {/* Adjust width for spacing */}
                                </div>
                                <div className="col-8 col-sm-3"><span
                                    className="sc-d02gry-2 bffEpg"
                                    style={{fontWeight: 'bold', fontSize: '15px'}}>Действия</span>
                                </div>
                                <div className="col-12">
                                    <div className="hr-line-solid  "></div>
                                </div>
                            </div>

                            {referralCodes.length === 0 ? (
                                <p>Не найдено ни одного реферального кода.</p>
                            ) : (
                                <div>
                                    {referralCodes.map(referralCode => (
                                        <div key={referralCode.id}>
                                            <div className="row">
                                                <div className="col-4 col-sm-2">{referralCode.name}</div>
                                                <div className="col-7 text-break">
                                                    <div className="form-control" style={{height: formControlHeight}}>
                                                        <a href={"https://" + ESU_DOMAIN + '/register?ref_code=' + referralCode.code.toString()}>
                                                            {"https://" + ESU_DOMAIN + '/register?ref_code=' + referralCode.code.toString()}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-3 text-break">
                                                    <button className="btn btn-primary mr-3 mb-1"
                                                            onClick={() => copyToClipboard("https://" + ESU_DOMAIN + '/register?ref_code=' + referralCode.code.toString())}>Скопировать
                                                    </button>
                                                    <CustomSelect
                                                        options={options}
                                                        placeholder="Действия"
                                                        selectedReferralCode={selectedOption}
                                                        handleSelectReferralCode={handleSelectOption}
                                                        referralCode={referralCode}
                                                    />
                                                    {/*<button className="btn btn-primary mb-1"*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            setNewReferralName(referralCode.name);*/}
                                                    {/*            setReferralCode(referralCode.code); // Set the code in state*/}
                                                    {/*            openEditReferralCodeModal(referralCode.code, referralCode.name);*/}
                                                    {/*        }}>Управлять*/}
                                                    {/*</button>*/}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="hr-line-solid  "></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <br/>

                        <p>Вы можете использовать ссылки для размещения в соцсетях, <br/>специализированных сайтах,
                            форумах, мессенджерах.</p>

                        <br/>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="col-md-6 p-0">
                                <input
                                    type="text"
                                    value={newReferralNameField}
                                    onChange={(e) => setNewReferralNameField(e.target.value)}
                                    placeholder="Введите название"
                                    className="form-control"
                                />
                            </div>

                            <button className="btn btn-primary ml-3" onClick={handleCreateReferralCode}>Создать
                                реферальный код
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralCodesPage;