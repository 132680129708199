import React from 'react';
import '../styles/ErrorModal.css'

const ErrorModal = ({message, onClose}) => {
    return (
        <div className="overlay">
            <div className="sc-saxv9r-2 eOHEUT modal-dialog modal-lg" tabIndex="-1"
                 data-html2canvas-ignore="true">
                <div className="modal-content">
                    <div className="modal-header" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            <h4 className="modal-title mb-3" style={{fontSize: "26px"}}>Ошибка</h4>
                        </div>
                        <div>
                            <ul className="text-danger mb-0">
                                <li>{message}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="modal-footer border-top-0">
                        <button className="sc-1eg8ud2-0 jWwwsM btn btn-default btn-lg" onClick={onClose}
                                type="button">Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;