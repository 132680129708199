import {BASE_URL} from "../const";

export const getClicks = async (date_start, date_end, referral_code) => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/clicks/?date_start=${date_start}&date_end=${date_end}&ref_code=${referral_code}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Ошибка получения кликов.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

export const addClicks = async (referral_code) => {
    try {
        const url = `${BASE_URL}api/clicks/`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"ref_code" : referral_code}),
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Failed to add referral click.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};