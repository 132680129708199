import React, {useEffect, useState} from 'react';

const EditReferralCodeModal = ({
                                   onClose,
                                   newReferralCodeName,
                                   setNewReferralCodeName,
                                   handleChangeReferralCode,
                                   handleDeleteReferralCode,
                                   referralCode,
                                   handleDeleteClick
                               }) => {

    return (
        <div className="overlay">
            <div className="sc-saxv9r-2 fxxhPo modal-dialog modal-lg" tabIndex="-1"
                 data-html2canvas-ignore="true">
                <div className="modal-content">
                    <div className="modal-header" style={{border: 'none'}}>
                        <div className="modal-header" style={{padding: '13px 30px'}}>
                            <h2 style={{fontWeight: 'bold'}} className="modal-title">Изменение реферального кода</h2>
                            <button type="button" className="close" data-dismiss="modal" onClick={onClose}><span
                                aria-hidden="true">×</span><span className="sr-only">Закрыть</span></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="text"
                                    value={newReferralCodeName}
                                    onChange={(e) => setNewReferralCodeName(e.target.value)}
                                    placeholder="Введите новое название"
                                    className="form-control"
                                />
                        </div>
                    </div>
                    <div className="modal-footer border-top-0">
                        <button className="btn btn-default" onClick={onClose}
                                type="button">Закрыть
                        </button>
                        <button className="btn btn-primary" onClick={handleChangeReferralCode}>Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditReferralCodeModal;