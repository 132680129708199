import {BASE_URL} from "../const";

export const getUserReferralCodes = async () => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${BASE_URL}api/referral-codes/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Ошибка получения реферальных кодов.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};


export const createUserReferralCode = async (name) => {
    try {
        const token = sessionStorage.getItem('token');
        const ref_code = sessionStorage.getItem('ref_code');
        const response = await fetch(`${BASE_URL}api/referral-codes/?ref_code=${ref_code}`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name})
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Failed to post referral code.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

export const getRegistrations = async (date_start, date_end) => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/referral-registrations/?date_start=${date_start}&date_end=${date_end}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Failed to get referral registrations.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

export const deleteReferralCode = async (code) => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/referral-codes/${code}`;
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log(response.status);
        if (response.status === 204) {
            const data = await response;
            return data;
        } else {
            throw new Error('Failed to delete.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

export const changeReferralCode = async (code, name) => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/referral-codes/${code}/`;
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'name': name}),
        });
        // console.log(response.status);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Failed to update.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};