import React, {useEffect, useRef, useState} from 'react';
import '../styles/CustomSelect.css'; // Add this CSS for custom styling

const CustomSelect = ({ options, placeholder, selectedReferralCode, handleSelectReferralCode, referralCode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedReferralCode);
    const dropdownRef = useRef(null);

    const handleOptionClick = (option) => {
        // console.log(option);
        handleSelectReferralCode(option, referralCode);
        setIsOpen(false);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);  // Close the dropdown if clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);  // Cleanup the event listener
        };
    }, []);

    return (
        <div className="custom-select-container" ref={dropdownRef}>
            {/* The visible selected element */}
            <div className={`btn btn-sm btn-default dropdown-toggle`} onClick={() => setIsOpen(!isOpen)} tabIndex="0">
                {selectedOption ? selectedOption : placeholder}
            </div>

            {/* The dropdown options */}
            {isOpen && (
                <ul className="custom-select-options">
                    {options.map((option) => (
                        <li
                            key={option}
                            className={`custom-select-option ${selectedOption === option ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option === 'Удалить' ? <div style={{color: 'red'}}>{option}</div> : option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
