import React, {useEffect, useRef, useState} from 'react';

import {useNavigate} from "react-router-dom";
import {useAuth} from "./AuthContext";

const UpperBar = () => {
    let navigate = useNavigate();
    const savedTheme = localStorage.getItem('theme') || 'light';
    const {logout} = useAuth();
    const [theme, setTheme] = useState(savedTheme); // Default theme is light
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const themeStyle = document.getElementById('theme-style');
        themeStyle.href = theme === 'light' ? 'css/lightTheme.css' : 'css/darkTheme.css';
        // Save the theme in local storage
        localStorage.setItem('theme', theme);

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
        // console.log("Logged out");
    };

    const handleDropdownClick = () => {
        setDropdownVisible(!isDropdownVisible);
        // console.log('dropdown');
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    return (
        <nav className="sc-1k6cxu7-1 fQhcfl navbar navbar-static-top flex-nowrap" role="navigation">
            <ul className="nav left-navbar">
                <li className="burger">
                    <button type="button" className="sc-1k6cxu7-3 jPfQhv btn btn-outline"><i
                        className="fas fa-2x fa-fw fa-bars"></i></button>
                </li>
            </ul>
            <ul className="nav right-navbar navbar-top-links navbar-right">
                <li className="vertical-devider"></li>
                <li><a onClick={toggleTheme}><i className="fas fa-adjust fa-lg"></i></a></li>
                <li className="dropdown"><a tabIndex="0" onClick={handleDropdownClick}><i
                    className="far fa-user fa-lg"></i></a>
                    {isDropdownVisible && (
                        <div ref={dropdownRef} tabIndex="-1">
                            <ul className="sc-1lmdhfl-0 kVIVHQ dropdown-menu show"
                                data-placement="top">
                                {/*<li><a className="dropdown-item" href="/profile/"><i className="far fa-user fa-gap"></i>Профиль</a>*/}
                                {/*</li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/rbac/client-2ca02977-a372-484b-85c4-0cdad9f47fa1/"><i*/}
                                {/*    className="fas fa-user-shield fa-gap"></i>Управление доступом Рыбол...</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/limits/client-2ca02977-a372-484b-85c4-0cdad9f47fa1/"><i*/}
                                {/*    className="fas fa-shield-alt fa-gap"></i>Лимиты по умолчанию Рыбол...</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-51bc60b3-d118-4957-9c90-f7fc8d1ee97d/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки Первый партнер</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-051886a0-79eb-48bc-9462-f54dd4e2c64c/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки MP-partner</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-a6d395ff-5468-4bc9-a796-cf9a5e49f7ff/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки Основной партнё...</a>*/}
                                {/*</li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-2e3be96a-e389-4cf2-98f8-34096a49ecdc/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки ООО "Облачное о...</a>*/}
                                {/*</li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-f054d5de-d81b-4b46-9f0d-f86d6d469f33/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки devtest_partner...</a>*/}
                                {/*</li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-ef68b6b9-79e1-4de5-ac6a-d2557de39789/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки devtest_partner</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-98cb18b3-bc70-4b8b-8a8e-a8995dacd55b/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки postman</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-1b1ac547-2b2e-499e-a6f2-2eb93dfffdca/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки MakeCloud</a></li>*/}
                                {/*<li><a className="dropdown-item"*/}
                                {/*       href="/admin/settings/partner-07c9b012-a8b6-45ef-a2ec-84dd27e6b63c/"><i*/}
                                {/*    className="fas fa-digital-tachograph fa-gap"></i>Настройки Айтеко Cloud</a></li>*/}
                                {/*<li className="dropdown-divider"></li>*/}
                                <li><a className="dropdown-item" tabIndex="0" href="/profile"><i
                                    className="far fa-user fa-gap"></i>Профиль</a></li>
                                <li><a className="dropdown-item" tabIndex="1" onClick={handleLogout}><i
                                    className="fas fa-sign-out-alt fa-gap"></i>Выход</a></li>
                            </ul>
                        </div>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default UpperBar;