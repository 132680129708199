import React from 'react';
import { ESU_DOMAIN } from "../const";

const Sidebar = () => {
    return (
        <nav className="sc-26g0x7-3 jbmRjj navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="sc-26g0x7-2 hVhVrB nav metismenu" id="side-menu">
                    <li className="nav-header">
                        <a className="logo" href="/"><img src={require('../assets/logo.svg').default} alt='logo' /></a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a className="ux-tour-iaas" href={`https://${ESU_DOMAIN}/iaas/`}>
                            <i className="fa fa-server"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Облачные вычисления</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/templates/`}>
                            <i className="fa fa-th-large"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Каталог образов</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/s3storage/`}>
                            <i className="fa fa-database"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Хранилища S3</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/kaas/`}>
                            <i className="fas fa-dharmachakra"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Кластеры Kubernetes</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href="http://kb.rustack.ru/ru/products/rustack-esu/docs">
                            <i className="fas fa-book"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">База знаний</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/dnsaas/`}>
                            <i className="fas fa-globe"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Доменные зоны</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/iac/`}>
                            <i className="fas fa-code"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Terraform</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/billing/`}>
                            <i className="fas fa-coins"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Баланс</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href={`https://${ESU_DOMAIN}/settings/`}>
                            <i className="fas fa-cogs"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Настройки</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 eZWcSR">
                        <a href="https://kb.iteco.cloud/index/panel-upravleniya/pol-zovatelyam/nachalo-raboty-s-panel-yu-upravleniya">
                            <i className="fas fa-book"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">База знаний</span>
                        </a>
                    </li>
                    <li className="sc-26g0x7-1 ciggMk active">
                        <a href="/referral-page">
                            <i className="fas fa-shopping-bag"></i>
                            <span className="sc-26g0x7-0 euJkTD nav-label">Партнерская программа</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;
