import React, {useEffect, useState} from "react";
import {getUserReferralCodes, createUserReferralCode} from '../api/referrals';
import {getBalance} from "../api/balance";
import {addWithdrawal, getWithdrawals} from "../api/withdrawal";
import {useError} from "./ErrorContext";
import WithdrawalModal from "./WithdrawalModal";
import ErrorModal from "./ErrorWindow";

const ReferralCodesPage = ({
                               notification, setNotification, amount,
                               setAmount, handleClients, balance,
                               withdrawals
                           }) => {
    const {showError} = useError();

    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${year}.${month}.${day}`;
    };

    const truncateToTwoDecimals = (num) => {
        return Math.floor(num * 100) / 100; // Truncate to 3 decimal places
    };

    return (
        <div className="wrapper wrapper-content ecommerce animated fadeInRight">
            <div className="row">
                <div className="col-lg-12">
                    <ul className="nav nav-tabs" role="tablist">
                        <li><a className="nav-link" href="/statistics" data-panel-id=".0">Статистика</a></li>
                        <li><a className="nav-link" href="/referral-page" data-panel-id=".1">Ссылки</a></li>
                        <li><a className="nav-link active" to="/withdrawal-page" data-panel-id=".2"
                               style={{marginBottom: '-2px', borderBottom: 'none', zIndex: '1010'}}>Вывод средств</a>
                        </li>
                    </ul>
                    <div className="ibox-content text-start py-3" style={{borderTop: 'none'}}>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <h3>Ваш баланс:</h3>
                            <button style={{
                                color: '#5bc0de',
                                textDecoration: 'underline',
                                marginLeft: '10px',
                                fontSize: '1.2em',
                                background: 'transparent',
                                border: 'none'
                            }} onClick={() => {
                                setAmount(truncateToTwoDecimals(parseFloat(balance)).toLocaleString('en-EN', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                }));
                            }}>
                                {truncateToTwoDecimals(parseFloat(balance)).toLocaleString('en-EN', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                })} руб.
                            </button>
                        </div>

                        <br/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="input-group-append input-group-prepend" style={{border: 'none'}}>
                                <div className="sc-zhzx7y-1 bGGOFt input-group-text"
                                     style={{
                                         fontSize: '0.75rem',
                                         padding: '0.55rem 0.7rem',
                                         borderRadius: '0',
                                         border: 'none',
                                     }}>₽
                                </div>
                            </div>
                            <div className="col-md-3 p-0">
                                <input
                                    type="text"
                                    value={amount}
                                    onChange={(e) => {
                                        // Allow only numbers and decimal points
                                        const newValue = e.target.value;
                                        if (/^\d*\.?\d*$/.test(newValue)) {
                                            setAmount(newValue);
                                            // showError('');
                                        }
                                    }}
                                    placeholder="Введите сумму"
                                    className="form-control"
                                />
                            </div>

                            <button className="btn btn-primary ml-3" onClick={handleClients}>Создать заявку на вывод
                            </button>
                        </div>

                        <div style={{height: '4em'}}></div>

                        <h3 style={{fontSize: '15px'}}>История вывода средств:</h3>
                        <br/>
                        <div>
                            <div className="row">
                                <div className="col-4 col-sm-2"><span className="sc-d02gry-2 bffEpg" style={{
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>Дата</span></div>
                                <div className="col-3"> {/* Spacer column */}
                                    <div style={{height: '1px'}}></div>
                                    {/* Adjust width for spacing */}
                                </div>
                                <div className="col-4 col-sm-2">
                                    <div className="row">
                                        <div className="col-8 col-sm-3"><span
                                            className="sc-d02gry-2 bffEpg"
                                            style={{fontWeight: 'bold', fontSize: '15px'}}>Cумма</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1"> {/* Spacer column */}
                                    <div style={{height: '1px'}}></div>
                                    {/* Adjust width for spacing */}
                                </div>
                                <div className="col-4 col-sm-2">
                                    <div className="row">
                                        <div className="col-8 col-sm-3"><span
                                            className="sc-d02gry-2 bffEpg"
                                            style={{fontWeight: 'bold', fontSize: '15px'}}>Почта</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="hr-line-solid  "></div>
                                </div>
                            </div>
                            {Array.isArray(withdrawals) && withdrawals.length > 0 ? (
                                withdrawals.map(([date, amount, email], index) => (
                                    <div key={index}>
                                        <div className="row">
                                            <div className="col-4 col-sm-2">
                                                {formatDate(date)}
                                            </div>
                                            <div className="col-3"> {/* Spacer column */}
                                                <div style={{height: '5px'}}></div>
                                            </div>
                                            <div className="col-8 col-sm-3 text-break">
                                                {truncateToTwoDecimals(parseFloat(amount)).toLocaleString('en-EN', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 2
                                                })} ₽
                                            </div>
                                            <div className="col-8 col-sm-3 text-break">
                                                {email}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="hr-line-solid"></div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="no-referrals">История вывода средств отсутствует</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralCodesPage;