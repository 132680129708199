import {BASE_URL} from '../const';

export const loginUser = async (email, password) => {
    try {
        const response = await fetch(`${BASE_URL}user/login/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, password}),
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            throw new Error('Неверный логин или пароль');
        } else {
            throw new Error('Ошибка во время логина, попробуйте еще раз');
        }
    } catch (error) {
        throw new Error(error.message);
    }
};
