import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import LoginPage from './components/LoginPage';
import ReferralStat from "./pages/ReferralStat";
import ReferralComp from "./pages/Referral";
import WithdrawalComp from "./pages/Withdrawal";
import {AuthProvider} from "./components/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Profile from "./pages/Profile";
import {ErrorProvider} from "./components/ErrorContext";
import {WithdrawalProvider} from "./components/WithdrawalContext";
import {EditReferralCodesProvider} from "./components/EditReferralCodeContext";
import {ConfirmProvider} from "./components/ConfirmContext";

function App() {
    return (
        <AuthProvider>
            <ErrorProvider>
                <WithdrawalProvider>
                    <ConfirmProvider>
                        <EditReferralCodesProvider>
                            <Router>
                                <Routes>
                                    <Route path="/login" element={<LoginPage/>}/>

                                    <Route element={<ProtectedRoute/>}>
                                        <Route path="/referral-page" element={<ReferralComp/>}/>
                                        <Route path="/statistics" element={<ReferralStat/>}/>
                                        <Route path="/withdrawal-page" element={<WithdrawalComp/>}/>
                                        <Route path="/profile" element={<Profile/>}/>
                                        <Route path="/" element={<ReferralStat/>}/>
                                    </Route>

                                    <Route path="*" element={<Navigate to="/"/>}/>
                                </Routes>
                            </Router>
                        </EditReferralCodesProvider>
                    </ConfirmProvider>
                </WithdrawalProvider>
            </ErrorProvider>
        </AuthProvider>
    );
}

export default App;