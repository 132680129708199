import React, { useState, useEffect } from 'react';

const Notification = ({ message, onClose }) => {
    useEffect(() => {
        // Automatically close the notification after 2 seconds
        const timer = setTimeout(() => {
            onClose();
        }, 2000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#18A68A',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            fontSize: '14px',
            zIndex: '3000',
            opacity: '1',
            transition: 'opacity 0.5s ease-in-out'
        }}>
            {message}
        </div>
    );
};

export default Notification;