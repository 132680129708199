import React, {useEffect, useState} from "react";
import {getUserReferralCodes, createUserReferralCode} from '../api/referrals';
import {useNavigate} from 'react-router-dom';
import {ESU_DOMAIN} from "../const";

const ReferralCodesPage = () => {

    const handleButtonClick = () => {
        window.location.href = `https://${ESU_DOMAIN}/settings/profile/`;
    };

    return (
        <div className="wrapper wrapper-content ecommerce animated fadeInRight">
            <div className="tabs-container">
                <ul className="nav nav-tabs" role="tablist">
                    <li><a className="nav-link active" href="/profile">Профиль</a></li>
                </ul>
                <div className="ibox-content text-start py-3">
                    <h3>Учетные данные:</h3>
                    <div className="referral-box">
                        <p><strong>Логин</strong></p>
                        <div className="referral-link-container">
                            <a>
                                {sessionStorage.getItem('login')}
                            </a>
                        </div>
                    </div>
                    <button className="create-button" onClick={handleButtonClick}>Изменить данные
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReferralCodesPage;