import React, {useEffect, useRef, useState} from "react";
import {getUserReferralCodes} from "../api/referrals";
import {getClicks} from "../api/click";
import ErrorModal from "./ErrorWindow";
import {useError} from "./ErrorContext";
import {FaCalendarAlt} from 'react-icons/fa';
import { ru } from 'date-fns/locale';

import CustomSelect from "./CustomDropdown";
import DatePicker from "react-datepicker";

const ReferralStatisticsPage = () => {
    const {showError} = useError();

    const [selectedReferralCode, setSelectedReferralCode] = useState(() => {
        // Retrieve and parse the stored referral code
        const storedReferralCode = sessionStorage.getItem('referralCode');
        return storedReferralCode ? JSON.parse(storedReferralCode) : null;
    });
    const [registrations, setRegistrations] = useState({});
    const [referralCodes, setReferralCodes] = useState([]);
    const dateInputRef = useRef(null);
    const datePickerRefLeft = useRef(null);
    const datePickerRefRight = useRef(null);

    const handleIconClickLeft = () => {
        datePickerRefLeft.current.setOpen(true); // Open the datepicker when the icon is clicked
    };
    const handleIconClickRight = () => {
        datePickerRefRight.current.setOpen(true); // Open the datepicker when the icon is clicked
    };

    const [startDate, setStartDate] = useState(() => {
        const storedStartDate = sessionStorage.getItem('startDate');
        if (storedStartDate) {
            return storedStartDate;
        } else {
            const today = new Date();
            today.setDate(today.getDate() - 1);
            return today.toISOString().split('T')[0];
        }
    });

    const [endDate, setEndDate] = useState(() => {
        const storedEndDate = sessionStorage.getItem('endDate');
        if (storedEndDate) {
            return storedEndDate;
        } else {
            return new Date().toISOString().split('T')[0];
        }
    });


    useEffect(() => {
        // console.log(1);
        const fetchReferralCodes = async () => {
            try {
                const codes = await getUserReferralCodes();
                setReferralCodes(codes);
            } catch (error) {
                console.error('Failed to fetch referral codes:', error.message);
                showError('Ошибка получения реферальных кодов');
            }
        };

        fetchReferralCodes();
    }, [showError]);

    useEffect(() => {
        sessionStorage.setItem('startDate', startDate);
    }, [startDate]);

    useEffect(() => {
        sessionStorage.setItem('endDate', endDate);
    }, [endDate]);

    useEffect(() => {
        sessionStorage.setItem('referralCode', JSON.stringify(selectedReferralCode));
    }, [selectedReferralCode]);

    const handleClick = () => {
        dateInputRef.current?.focus(); // Focus the input to trigger the date picker
    };

    const handleGetStatistics = async () => {
        // console.log(1);
        try {
            if (!startDate || !endDate || !selectedReferralCode) {
                throw new Error('Пожалуйста, заполните все поля: дату начала, дату конца и выберите реферальный код.');
            }
            // console.log(1);
            // console.log(selectedReferralCode);

            const response = await getConstClicks(startDate, endDate, selectedReferralCode.code);
            setRegistrations(response);
        } catch (error) {
            // console.error('Failed to fetch clicks:', error.message);
            showError(error.message);
        }
    };

    const handleSelectReferralCode = (selectedValue) => {
        if (selectedValue) {
            const selectedCode = JSON.parse(selectedValue);
            setSelectedReferralCode(selectedCode);
        } else {
            setSelectedReferralCode(null);
        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear(); // Get the year (yyyy)
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (mm), ensure it's 2 digits
        const day = date.getDate().toString().padStart(2, '0'); // Get the day (dd), ensure it's 2 digits

        return `${year}-${month}-${day}`; // Return in yyyy-mm-dd format
    };

    const getConstClicks = async (startDate, endDate, code) => {
        try {
            const response = await getClicks(startDate, endDate, code);
            return response;
        } catch (error) {
            console.error('Failed to fetch clicks:', error.message);
            showError('Ошибка получения статистики');
        }
    };

    const truncateToTwoDecimals = (num) => {
        return Math.floor(num * 100) / 100; // Truncate to 3 decimal places
    };

    return (
        <div className="wrapper wrapper-content ecommerce animated fadeInRight">
            <div className="row">
                <div className="col-lg-12">
                    <ul className="nav nav-tabs" role="tablist">
                        <li><a className="nav-link active" to="/statistics" data-panel-id=".0"
                               style={{marginBottom: '-2px', borderBottom: 'none', zIndex: '1010'}}>Статистика</a></li>
                        <li><a className="nav-link" href="/referral-page" data-panel-id=".1">Ссылки</a></li>
                        <li><a className="nav-link " href="/withdrawal-page" data-panel-id=".2">Вывод средств</a>
                        </li>
                    </ul>
                    <div className="ibox-content text-start py-3">
                        <h3>Выберите реферальный код и даты:</h3>

                        <br/>

                        <div className="col-4 mb-5 p-0">
                            <CustomSelect
                                options={referralCodes}
                                placeholder="Выберите..."
                                selectedReferralCode={selectedReferralCode}
                                handleSelectReferralCode={handleSelectReferralCode}
                            />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center'}} className="mb-3">
                            <div style={{position: 'relative', display: 'inline-block'}}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(formatDate(date))}
                                    className="form-control"
                                    popperPlacement="bottom" // Optional: control where the calendar pops up
                                    dateFormat="yyyy-MM-dd"
                                    ref={datePickerRefLeft}
                                    locale={ru}
                                    style={{paddingRight: '30px'}} // Add padding to the right to avoid overlap with icon
                                />
                                <span
                                    onClick={handleIconClickLeft}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        zIndex: 1 // Ensure icon is above input field
                                    }}
                                >
                                    <FaCalendarAlt/> {/* Calendar icon */}
                                </span>
                            </div>
                            <div className="input-group-append input-group-prepend" style={{border: 'none'}}>
                                <div className="sc-zhzx7y-1 bGGOFt input-group-text"
                                     style={{
                                         fontSize: '0.75rem',
                                         padding: '0.55rem 0.7rem',
                                         borderRadius: '0',
                                         border: 'none',
                                     }}>—
                                </div>
                            </div>
                            <div style={{position: 'relative', display: 'inline-block'}}>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(formatDate(date))}
                                    className="form-control"
                                    popperPlacement="bottom" // Optional: control where the calendar pops up
                                    dateFormat="yyyy-MM-dd"
                                    ref={datePickerRefRight}
                                    locale={ru}
                                    style={{paddingRight: '30px'}}
                                />

                                <span
                                    onClick={handleIconClickRight}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        zIndex: 1 // Ensure icon is above input field
                                    }}
                                >
                                    <FaCalendarAlt/> {/* Calendar icon */}
                                </span>
                            </div>

                            <button className="btn btn-primary ml-3" onClick={handleGetStatistics}>
                                Получить статистику
                            </button>
                        </div>

                        <br/>

                        <div>
                            <h3>Реферальная статистика за выбранные даты:</h3>
                            <br/>

                            <div className="row">
                                <div className="col-4 col-sm-2"><span className="sc-d02gry-2 bffEpg" style={{
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>Дата</span></div>
                                <div className="col-1"> {/* Spacer column */}
                                    <div style={{height: '1px'}}></div>
                                    {/* Adjust width for spacing */}
                                </div>
                                <div className="col-8 col-sm-3"><span
                                    className="sc-d02gry-2 bffEpg"
                                    style={{fontWeight: 'bold', fontSize: '15px'}}>Переходы</span>
                                </div>
                                <div className="col-8 col-sm-3"><span
                                    className="sc-d02gry-2 bffEpg"
                                    style={{fontWeight: 'bold', fontSize: '15px'}}>Регистрации</span>
                                </div>
                                <div className="col-8 col-sm-3"><span
                                    className="sc-d02gry-2 bffEpg"
                                    style={{fontWeight: 'bold', fontSize: '15px'}}>Баланс</span>
                                </div>
                                <div className="col-12">
                                    <div className="hr-line-solid  "></div>
                                </div>
                            </div>
                        </div>

                        {registrations ? (
                            <div>
                                {Object.entries(registrations).map(([date, number]) => (
                                    <div key={date}>
                                        <div className="row">
                                            <div className="col-4 col-sm-2">{date}</div>
                                            <div className="col-1"> {/* Spacer column */}
                                                <div style={{height: '5px'}}></div>
                                                {/* Adjust width for spacing */}
                                            </div>
                                            <div className="col-8 col-sm-3 text-break">{number[0]}</div>
                                            <div className="col-8 col-sm-3 text-break">{number[1]}</div>
                                            <div
                                                className="col-8 col-sm-3 text-break">{truncateToTwoDecimals(parseFloat(number[2])).toLocaleString('en-EN', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 2
                                            })} ₽
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="hr-line-solid  "></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="no-referrals">Рефералы отсутствуют</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralStatisticsPage;