import React from 'react';
import Sidebar from '../components/Sidebar';
import UpperBar from "../components/UpperBar";
import WhiteBlock from "../components/WhiteBlock";
import ReferralStatisticsPage from "../components/ReferralStatisticsPage";

const ReferralComp = () => {
    return (
        <div id="wrapper">
            <Sidebar />
            <div id="page-wrapper" className="gray-bg dashbard-1">
                <UpperBar />
                <WhiteBlock />
                <ReferralStatisticsPage />
            </div>
        </div>
    );
};

export default ReferralComp;