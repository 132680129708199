import React, {useEffect, useState} from 'react';
import ErrorModal from "./ErrorWindow";
import {getBalance} from "../api/balance";
import {addWithdrawal, getWithdrawals} from "../api/withdrawal";

const WithdrawalModal = ({clients, onClose, onClientSelect, handleError, clearError, setClientEntities}) => {
    const [selectedClientId, setSelectedClientId] = useState('');

    useEffect(() => {
        const fetchClientEntities = async () => {
            try {
                const response = await addWithdrawal();
                // console.log('API Data:', response); // Log the data to check its structure
                if (Array.isArray(response.clientIds)) {
                    setClientEntities(response.clientIds); // Set the array of clients
                } else {
                    console.error('Unexpected data format:', response);
                    setClientEntities([]);
                }
            } catch (error) {
                throw new Error(error);
            }
        }

        fetchClientEntities();
    }, [setClientEntities]);

    const handleClientSelect = () => {
        if (selectedClientId) {
            onClientSelect(selectedClientId);
            onClose();
        } else {
            handleError('Нужно выбрать клиента');
        }
    };

    return (
        <div className="overlay">
            <div style={{z: '1004'}} data-html2canvas-ignore="true" className="sc-saxv9r-0 kaKWLQ"></div>
            <div className="sc-saxv9r-2 fxxhPo modal-dialog modal-lg" tabIndex="-1"
                 data-html2canvas-ignore="true">
                <div className="modal-content">
                    <div className="modal-header" style={{padding: '13px 30px'}}>
                        <h2 style={{fontWeight: 'bold'}} className="modal-title">Выберите клиента для вывода</h2>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose}><span
                        aria-hidden="true">×</span><span className="sr-only">Закрыть</span></button>
                    </div>
                    <div className="modal-body">
                        <div className="sc-aoon89-4 fpYIsc modal-body" style={{padding: '10px 20px'}}>
                            <div className="table-responsive">
                                <div className="sc-1fid5ih-3 fkUfMw">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr className="d-none d-sm-table-row">
                                            <th></th>
                                            <th>Имя</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(clients) && clients.length > 0 ? (
                                            clients.map(([id, name]) => (
                                                <tr
                                                    className="sc-aoon89-2 jjdQsC"
                                                    key={id}
                                                    onClick={() => setSelectedClientId(id)}
                                                    style={{cursor: 'pointer'}} // Optional: add pointer cursor
                                                >
                                                    <td style={{width: '1%'}}>
                                                        <div className="sc-3iy329-1 eNsBRq d-block">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value={id}
                                                                    checked={selectedClientId === id}
                                                                    onChange={() => setSelectedClientId(id)} // Update state on radio change
                                                                />
                                                                <span className="sc-3iy329-0 bqsqGG"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>{name}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className="text-center" disabled>Нет доступных
                                                    клиентов
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-top-0">
                        <button className="btn btn-default" onClick={onClose}
                                type="button">Закрыть
                        </button>
                        <button className="btn btn-primary" onClick={handleClientSelect}
                                type="button">Отправить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithdrawalModal;