import React from 'react';

const WhiteBlock = () => {

    return (
        <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
                <h2>Партнерская программа</h2>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="referral-page">Главная</a>
                    </li>
                    <li className="breadcrumb-item active">
                        <strong>Партнерская программа</strong>
                    </li>
                </ol>
            </div>
            <div className="col-lg-2">

            </div>
        </div>
    );
};

export default WhiteBlock;