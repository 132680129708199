import React, { createContext, useContext, useState } from 'react';
import ConfirmModal from './ConfirmModal';

const ConfirmContext = createContext();

export const ConfirmProvider = ({ children }) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [onConfirmAction, setOnConfirmAction] = useState(() => {});

    const showConfirm = (message, onConfirm) => {
        setConfirmMessage(message);
        setOnConfirmAction(() => () => {
            onConfirm();
            closeConfirm();
        });
        setIsConfirmOpen(true);
    };

    const closeConfirm = () => {
        setIsConfirmOpen(false);
        setConfirmMessage('');
        setOnConfirmAction(() => {});
    };

    return (
        <ConfirmContext.Provider value={{ showConfirm }}>
            {children}
            {isConfirmOpen && (
                <ConfirmModal
                    message={confirmMessage}
                    onConfirm={onConfirmAction}
                    onCancel={closeConfirm}
                />
            )}
        </ConfirmContext.Provider>
    );
};

export const useConfirm = () => useContext(ConfirmContext);