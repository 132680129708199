import {BASE_URL} from "../const";

export const getBalance = async (date_start, date_end, referral_code) => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/balance`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Ошибка при получении баланса.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};