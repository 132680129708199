import React, { createContext, useState, useContext } from 'react';
import WithdrawalModal from "./WithdrawalModal";
import EditReferralCodeModal from "./EditReferralCodeModal";

const EditReferralCodesContext = createContext();

export const EditReferralCodesProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(null);

    const showEditReferralCodes = (clients) => {
        setIsOpen(clients);
    };

    const clearEditReferralCodes = () => {
        setIsOpen(null);
    };

    return (
        <EditReferralCodesContext.Provider value={{ showEditReferralCodes, clearEditReferralCodes }}>
            {children}
            {isOpen && <EditReferralCodeModal onClose={clearEditReferralCodes} />}
        </EditReferralCodesContext.Provider>
    );
};

export const useEditReferralCodes = () => useContext(EditReferralCodesContext);