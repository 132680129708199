import React, {useEffect, useRef, useState} from 'react';
import {loginUser} from '../api/auth';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {useAuth} from "./AuthContext";
import ErrorModal from "./ErrorWindow";
import {ESU_DOMAIN} from "../const";

const LoginPage = () => {
    const {login} = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [hasMounted, setHasMounted] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    let navigate = useNavigate();

    const handleError = (errorMessage) => {
        setError(errorMessage);
    };

    const clearError = () => {
        setError(null);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await loginUser(email, password);

            if (response['token'] && response['token'] !== '') {
                login();
                sessionStorage.setItem('token', response['token']);
                sessionStorage.setItem('login', email);
                navigate('/referral-page');
            } else if (response.status === 401) {
                setError('Неправильный логин или пароль');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        setHasMounted(true);
    }, []); // Mark the component as mounted after the initial render

    useEffect(() => {
        if (hasMounted) {
            // Check if the fields are already populated
            // console.log(email);
            // console.log(emailRef.current.value);
            if (email || emailRef.current || emailRef.current.value) {
                setIsEmailFocused(true);
            }
            // console.log(password);
            // console.log(passwordRef.current.value);
            if (password || passwordRef.current || passwordRef.current.value) {
                setIsPasswordFocused(true);
            }
        }
    }, [hasMounted, email, password]);

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="left-column">
                    <form className="form-group" onSubmit={handleLogin}>
                        <div className="form-group">
                            <label className={`placeholder ${isEmailFocused || email ? 'focused' : ''}`}>
                                Логин
                            </label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={email}
                                ref={emailRef}
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setIsEmailFocused(true)}
                                onBlur={() => setIsEmailFocused(false)}
                            />
                        </div>
                        <div className="form-group password-group">
                            <label className={`placeholder ${isPasswordFocused || password ? 'focused' : ''}`}>
                                Пароль
                            </label>
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={password}
                                ref={passwordRef}
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={() => setIsPasswordFocused(true)}
                                onBlur={() => setIsPasswordFocused(false)}
                            />
                            <button
                                type="button"
                                className="password-toggle-button"
                                onClick={() => setShowPassword(!showPassword)}
                                aria-label="Toggle password visibility"
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} color="#aaa"/>
                            </button>
                        </div>
                        <button type="submit">ВОЙТИ</button>
                        <a style={{marginTop: '16px',}} href={`https://${ESU_DOMAIN}/register`}>Еще нет аккаунта?
                            Зарегистрироваться!</a>
                        {/*{error && <p className="error-message">{error}</p>}*/}
                    </form>
                </div>
                <div className="divider"></div>
                <div className="right-column">
                    <h2 className="h2">Вход</h2>
                    <p className="p">Это реферальная система платформы MakeCloud. Чтобы начать участвовать, пройдите
                        регистрацию/авторизуйтесь и делитесь своим реферальным кодом!</p>
                </div>
            </div>
            {error && <ErrorModal message={error} onClose={clearError}/>}
        </div>
    );
};

export default LoginPage;
