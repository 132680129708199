import {BASE_URL} from "../const";

export const getWithdrawals = async () => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/withdrawal`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Ошибка при получении информации о выводе средств.');
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

export const addWithdrawal = async (amount = 0, client_id = null) => {
    try {
        const token = sessionStorage.getItem('token');
        const url = `${BASE_URL}api/withdrawal/`;
        if (client_id === null) {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"amount": amount}),
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error('Ошибка при попытке вевести средства.');
            }
        } else {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"amount": amount, "user_id": client_id}),
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error('Ошибка при попытке вевести средства.');
            }
        }
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};