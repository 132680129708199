import React, {useEffect, useState} from 'react';
import Sidebar from '../components/Sidebar';
import UpperBar from "../components/UpperBar";
import WhiteBlock from "../components/WhiteBlock";
import ReferralCodesPage from "../components/ReferralCodesPage";
import Notification from "../components/Notification";
import {useError} from "../components/ErrorContext";
import ErrorModal from "../components/ErrorWindow";
import EditReferralCodeModal from "../components/EditReferralCodeModal";
import {changeReferralCode, createUserReferralCode, deleteReferralCode, getUserReferralCodes} from "../api/referrals";
import ConfirmModal from "../components/ConfirmModal";

const ReferralComp = () => {
    const {showError} = useError();

    const [notification, setNotification] = useState(null);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(null);
    const [newReferralCodeName, setNewReferralCodeName] = useState();
    const [referralCodes, setReferralCodes] = useState([]);
    const [newReferralName, setNewReferralName] = useState('');
    const [newReferralNameField, setNewReferralNameField] = useState('');
    const [referralCode, setReferralCode] = useState();
    const [isConfirmOpen, setIsConfirmOpen] = useState();

    useEffect(() => {
        // if (referralCode) {
        //     console.log('Updated referralCode:', referralCode);
        // }
        fetchReferralCodes();
    }, [referralCode, isConfirmOpen]);

    const handleDeleteClick = () => {
        setIsConfirmOpen(true);
    };

    const handleCancelDelete = () => {
        setIsConfirmOpen(null);
    };

    const handleDeleteReferralCode = async (code) => {
        try {
            const response = await deleteReferralCode(code);
            fetchReferralCodes();
            setIsOpen(null);
            setNotification('Реферальный код успешно удален!');
            // console.log('Success');
        } catch (error) {
            setIsOpen(null);
            setError('Не удалось удалить реферальный код');
            console.error('Failed to delete referral code:', error.message);
        }
    };

    const handleConfirmDelete = () => {
        handleDeleteReferralCode(referralCode.toString());
        setIsConfirmOpen(null);
    };

    const openEditReferralCodeModal = async (code, name) => {
        setNewReferralCodeName(name);
        setReferralCode(code); // Update the state with the code
        setIsOpen(true);
        // console.log(code);
    }
    const closeEditReferralCodeModal = async () => {
        setIsOpen(null);
    }

    const fetchReferralCodes = async () => {
        try {
            const codes = await getUserReferralCodes();
            setReferralCodes(codes);
        } catch (error) {
            console.error('Failed to fetch referral codes:', error.message);
        }
    };

    const handleCreateReferralCode = async () => {
        try {
            await createUserReferralCode(newReferralNameField);
            const codes = await getUserReferralCodes();
            setReferralCodes(codes);
            setNewReferralNameField('');
            setNotification('Реферальный код успешно создан!');
            // console.log('Success');
        } catch (error) {
            console.error('Failed to create referral code:', error.message);
        }
    };

    const handleChangeReferralCode = async () => {
        try {
            const response = await changeReferralCode(referralCode, newReferralCodeName);
            fetchReferralCodes();
            setIsOpen(null);
            setReferralCode(null);
            setNewReferralCodeName(null);
            setNotification('Название реферального кода успешно изменено!');
            // console.log('Success');
        } catch (error) {
            setIsOpen(null);
            setError('Не удалось изменить название реферального кода');
            // console.error('Failed to delete referral code:', error.message);
        }
    };
    const clearError = () => {
        setError(null);
    };

    return (
        <div id="wrapper">
            <Sidebar/>
            <div id="page-wrapper" className="gray-bg dashbard-1">
                <UpperBar/>
                <WhiteBlock/>
                <ReferralCodesPage
                    notification={notification}
                    setNotification={setNotification}
                    setError={setError}
                    referralCodes={referralCodes}
                    handleDeleteReferralCode={handleDeleteReferralCode}
                    newReferralName={newReferralName}
                    setNewReferralName={setNewReferralName}
                    handleCreateReferralCode={handleCreateReferralCode}
                    openEditReferralCodeModal={openEditReferralCodeModal}
                    setReferralCode={setReferralCode}
                    newReferralNameField={newReferralNameField}
                    setNewReferralNameField={setNewReferralNameField}
                    handleDeleteClick={handleDeleteClick}
                />
                {notification && (
                    <Notification
                        message={notification}
                        onClose={() => setNotification(null)}
                    />
                )}
            </div>
            {isOpen && (
                <EditReferralCodeModal
                    onClose={closeEditReferralCodeModal}
                    newReferralCodeName={newReferralCodeName}
                    setNewReferralCodeName={setNewReferralCodeName}
                    handleChangeReferralCode={handleChangeReferralCode}
                    handleDeleteReferralCode={handleDeleteReferralCode}
                    referralCode={referralCode}
                    handleDeleteClick={handleDeleteClick}
                />
            )}
            {isConfirmOpen && (
                <ConfirmModal
                    message="Вы уверены, что хотите удалить этот реферальный код?"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
            {error && <ErrorModal message={error} onClose={clearError}/>}
        </div>
    );
};

export default ReferralComp;